// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../styleguide/components/Heading/H2.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_State from "../../../../routes/common/Routes_State.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexLocality from "../../index/components/common/LocationsIndexLocality.res.js";
import * as LocationsIndexProvider from "../../index/components/common/LocationsIndexProvider.res.js";
import * as LocalityShowStatesScss from "./LocalityShowStates.scss";

var css = LocalityShowStatesScss;

function LocalityShowStates(props) {
  var country = props.country;
  return JsxRuntime.jsxs("div", {
              children: [
                country.cityState ? null : JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(H2.make, {
                                className: css.localitiesTitle,
                                children: country.name + ": Top " + country.administrativeDivisionPlural + " with Data Centers"
                              }),
                          JsxRuntime.jsxs("div", {
                                children: [
                                  Belt_Array.mapWithIndex(props.states.states, (function (index, state) {
                                          return JsxRuntime.jsx(LocationsIndexLocality.make, {
                                                      name: state.name,
                                                      href: Routes_State.show(state.slug, country.slug),
                                                      totalLocations: state.totalLocations
                                                    }, String(index));
                                        })),
                                  JsxRuntime.jsx("a", {
                                        children: "View All " + country.administrativeDivisionPlural,
                                        href: Routes_State.index
                                      })
                                ],
                                className: css.localitiesList
                              })
                        ],
                        className: css.localities
                      }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              className: css.localitiesTitle,
                              children: country.name + ": Top Data Center Providers"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                Belt_Array.mapWithIndex(props.providers.providers, (function (index, provider) {
                                        return JsxRuntime.jsx(LocationsIndexProvider.make, {
                                                    name: provider.name,
                                                    slug: provider.slug,
                                                    totalLocations: provider.totalLocations
                                                  }, String(index));
                                      })),
                                JsxRuntime.jsx("a", {
                                      children: "View All Providers",
                                      href: Routes_Provider.index
                                    })
                              ],
                              className: css.providersList
                            })
                      ],
                      className: css.providers
                    })
              ],
              className: css.localitiesContainer
            });
}

var make = LocalityShowStates;

export {
  css ,
  make ,
}
/* css Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../styleguide/components/Heading/H2.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexProvider from "../../index/components/common/LocationsIndexProvider.res.js";
import * as LocalityShowProvidersScss from "./LocalityShowProviders.scss";

var css = LocalityShowProvidersScss;

function LocalityShowProviders(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(H2.make, {
                            className: css.localitiesTitle,
                            children: props.localityName + ": Top Data Center Providers"
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              Belt_Array.mapWithIndex(props.providers.providers, (function (index, provider) {
                                      return JsxRuntime.jsx(LocationsIndexProvider.make, {
                                                  name: provider.name,
                                                  slug: provider.slug,
                                                  totalLocations: provider.totalLocations
                                                }, String(index));
                                    })),
                              JsxRuntime.jsx("a", {
                                    children: "View All Providers",
                                    href: Routes_Provider.index
                                  })
                            ],
                            className: css.providersList
                          })
                    ],
                    className: css.providers
                  }),
              className: css.localitiesContainer
            });
}

var make = LocalityShowProviders;

export {
  css ,
  make ,
}
/* css Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../styleguide/components/Heading/H2.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_City from "../../../../routes/common/Routes_City.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexLocality from "../../index/components/common/LocationsIndexLocality.res.js";
import * as LocationsIndexProvider from "../../index/components/common/LocationsIndexProvider.res.js";
import * as LocalityShowCitiesScss from "./LocalityShowCities.scss";

var css = LocalityShowCitiesScss;

function LocalityShowCities(props) {
  var countryState = props.countryState;
  var country = props.country;
  var localityName = props.localityName;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              className: css.localitiesTitle,
                              children: localityName + ": Top Cities with Data Centers"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                Belt_Array.mapWithIndex(props.cities.cities, (function (index, city) {
                                        return JsxRuntime.jsx(LocationsIndexLocality.make, {
                                                    name: city.name,
                                                    href: Routes_City.show(city.slug, countryState.slug, country.slug),
                                                    totalLocations: city.totalLocations
                                                  }, String(index));
                                      })),
                                JsxRuntime.jsx("a", {
                                      children: "View All Cities",
                                      href: Routes_City.index
                                    })
                              ],
                              className: css.localitiesList
                            })
                      ],
                      className: css.localities
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              className: css.localitiesTitle,
                              children: localityName + ": Top Data Center Providers"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                Belt_Array.mapWithIndex(props.providers.providers, (function (index, provider) {
                                        return JsxRuntime.jsx(LocationsIndexProvider.make, {
                                                    name: provider.name,
                                                    slug: provider.slug,
                                                    totalLocations: provider.totalLocations
                                                  }, String(index));
                                      })),
                                JsxRuntime.jsx("a", {
                                      children: "View All Providers",
                                      href: Routes_Provider.index
                                    })
                              ],
                              className: css.providersList
                            })
                      ],
                      className: css.providers
                    })
              ],
              className: css.localitiesContainer
            });
}

var make = LocalityShowCities;

export {
  css ,
  make ,
}
/* css Not a pure module */
